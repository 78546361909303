// Home pae styles
.bio_list {
    font-size: 0.75em;
    list-style-type: none;
}


// Publication page styles
.pub_list {
    font-size: 0.75em;
}

.pub_num {
    vertical-align: top;
}

.journal {
    font-style: italic;
    font-weight: 600;
    color: #494e52;
}

.conference {
    font-weight: 600;
    color: #494e52;
}

.pub_details {
    color: #8C8C8C;
}


// Talks page styles
.talks_list {
    font-size: 0.75em;
}


// Teaching page styles
.teaching_list {
    font-size: 0.75em;
}


// Workshop page styles
.stage-earth {background-color: #FFA726};
.stage-mercury {background-color: #9CCC65};
.stage-venus {background-color: #FF8A65};
.stage-mars {background-color: #B3E5FC};
.stage-jupiter {background-color: #81D4FA};
.stage-saturn {background-color: #26C6DA};

.workshop_header{
    margin-top: 20px;
    text-align: center;
}

.workshop_header_h1{
    margin-bottom: 0px;
}


.speaker {
    font-size: 0.8rem;
    font-weight: bold;
}

.schedule {
    width: 100%;
    max-width: 50rem;
    border-collapse: separate;
    display: table;
    
    tr:nth-child(even) th {
        color: #ccc;
        font-weight: normal;
    }
    
    th,
    td {
        padding: 0.5rem 1rem;
    }
    
    th {
        font-weight: normal;
        border-top: thin dotted #ccc;
        text-align: center;
    }
    
    td {
        font-size: 0.8rem;
        font-weight: bold;
        line-height: 1.4;
        border-radius: 0.2rem;
        transition: opacity 0.3s ease;
    }
    
    td > span {
        font-size: 0.8em;
        font-weight: normal;
        display: block;
        width: 100%;
    }
}

.non_talk {
    font-weight: normal;
}
