.shortBio {
    font-size: 0.9em;
    margin-top: 50px;
}

.bio_table {
    padding-left: 3%;
    padding-right: 3%;
    width: 100%;
}

.bio_record {
    font-family: Tahoma,Verdana,Sans-Serif;
}

.bio_record_period {
    width: 20%;
    vertical-align: top;
    color: #8C8C8C;
}

.bio_record_descr {
    vertical-align: top;
    padding-bottom: 6px;
}

.sidebar {
    opacity: 1;
}

/*
.sidebar img {
    max-width: 300px;
}
*/